import {
  GET_VARIABLES_FAIL,
  GET_VARIABLES_FETCHING,
  GET_VARIABLES_SUCCESS,
  MODALS_VARIABLES_FETCHING,
  MODALS_VARIABLES_FAIL,
  MODALS_VARIABLES_SUCCESS,
} from '../actions/variablesActions';

const initialState = {
  data: {
    variables: [],
    sharedVariables: [],
    totalRecords: 0,
  },
  loading: false,
  errors: [],
  modalLoading: false,
  modalErrors: [],
};

const variablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VARIABLES_FETCHING:
      return { ...state, loading: true };
    case GET_VARIABLES_FAIL:
      return { ...state, loading: false, errors: action.payload.errors };
    case GET_VARIABLES_SUCCESS:      
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        errors: [],
      };
    case MODALS_VARIABLES_FETCHING:
      return { ...state, modalLoading: true };
    case MODALS_VARIABLES_SUCCESS:
      return { ...state, modalLoading: false, modalErrors: [] };

    case MODALS_VARIABLES_FAIL:
      return {
        ...state,
        modalLoading: false,
        modalErrors: action.payload.errors,
      };
    default:
      return state;
  }
};

export default variablesReducer;
