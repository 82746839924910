import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Scrollbar from 'react-smooth-scrollbar';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SidebarContent from './SidebarContent';
import { SidebarProps } from '../../../shared/prop-types/ReducerProps';
import { backToAdminRequest } from '../../../redux/actions/authActions';
import LocationExitIcon from "mdi-react/LocationExitIcon";

const Sidebar = ({
  changeToDark,
  changeToLight,
  changeMobileSidebarVisibility,
  sidebar,
}) => {
  const sidebarClass = classNames({
    sidebar: true,
    'sidebar--show': sidebar.show,
    'sidebar--collapse': sidebar.collapse,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginAsBoolean } = useSelector(state => state.auth);

  return (
    <div className={sidebarClass}>
      <button
        className="sidebar__back"
        type="button"
        onClick={changeMobileSidebarVisibility}
      />
      <Scrollbar className="sidebar__scroll scroll">
        <Fragment>
          <div className="sidebar__wrapper sidebar__wrapper--desktop">
            <SidebarContent
              onClick={() => {}}
              changeToDark={changeToDark}
              changeToLight={changeToLight}
              sidebarCollapse={sidebar.collapse}
            />
          </div>
          <div className="sidebar__wrapper sidebar__wrapper--mobile">
            <SidebarContent
              onClick={changeMobileSidebarVisibility}
              changeToDark={changeToDark}
              changeToLight={changeToLight}
              sidebarCollapse={sidebar.collapse}
            />
          </div>
          {
            loginAsBoolean ? (!sidebar.collapse ? (
              <div className="d-flex d-md-none align-items-center" style={{margin: '11px 20px'}}>
                <button
                  className="btn btn-primary mb-0"
                  style={{whiteSpace: 'nowrap'}}
                  onClick={() => dispatch(backToAdminRequest(navigate))}
                >
                  Login back as ADMIN
                </button>
              </div>
            ) : (<button
              style={{border: 0, backgroundColor: "transparent", paddingBottom: "20px"}}
              onClick={() => dispatch(backToAdminRequest(navigate))}
              className="d-flex d-md-none justify-content-center"
            >
              <LocationExitIcon size={24} color="#70bbfd" />
            </button>)) : null
          }
        </Fragment>
      </Scrollbar>
    </div>
  );
};

Sidebar.propTypes = {
  sidebar: SidebarProps.isRequired,
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
};

export default Sidebar;
