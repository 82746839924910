import {
  GET_ENVIRONMENTS_FAIL,
  GET_ENVIRONMENTS_FETCHING,
  GET_ENVIRONMENTS_SUCCESS,
  POST_ENVIRONMENTS_FAIL,
  POST_ENVIRONMENTS_FETCHING,
} from '../actions/environmentsActions';

const initialState = {
  environments: [],
  totalRecords: 0,
  loading: false,
  errors: [],
  modalLoading: false,
  modalErrors: [],
};

const environmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ENVIRONMENTS_FETCHING:
      return { ...state, loading: true };
    case GET_ENVIRONMENTS_FAIL:
      return { ...state, loading: false, errors: action.payload.errors };
    case GET_ENVIRONMENTS_SUCCESS:
      return {
        ...state,
        environments: action.payload.resources,
        totalRecords: Number(action.payload.total_records),
        loading: false,
        errors: [],
      };
    case POST_ENVIRONMENTS_FETCHING:
      return { ...state, modalLoading: true };
    case POST_ENVIRONMENTS_FAIL:
      return {
        ...state,
        modalLoading: false,
        modalErrors: action.payload.errors,
      };
    default:
      return state;
  }
};

export default environmentsReducer;
